import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormText,
  Row,
} from "reactstrap";
import Header from "../../../../components/Headers/Header";
import { ReducerState } from "../../../../registrator";
import CollectionState from "../../contracts/CollectionState";
import { Form, Control, Errors, actions } from "react-redux-form";
import {
  receiptData,
  receiptVerification,
  tenantListData,
  receiptDataReset,
} from "../../actions/collection";
import moment from "moment";
import store from "../../../../store";
import "react-image-lightbox/style.css";
import { RouteComponentProps } from "react-router";
import VerificationForm from "../../contracts/VerificationForm";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

interface DerivedProps
  extends RouteComponentProps<{
    receiptId: any;
  }> {
  receiptData: typeof receiptData;
  receiptVerification: typeof receiptVerification;
  receiptDataReset: typeof receiptDataReset;
  tenantListData: typeof tenantListData;
  collection: CollectionState;
}

class States {
  reasonList: any = [];
  dateTime: any = new Date();
}

class Verification extends React.Component<DerivedProps, States> {
  state: States = new States();
  componentDidMount() {
    const { match, receiptData } = this.props;
    receiptData(match.params.receiptId);
  }

  componentDidUpdate() {
    if (this.state.reasonList.length == 0) {
      const { collection } = this.props;
      this.onStatusChange(
        collection.receiptDetail.status,
        collection.receiptData.reasons
      );
    }
  }

  componentWillUnmount() {
    const { receiptDataReset } = this.props;
    receiptDataReset();
  }

  handleReceiptVerification = (verificationForm: VerificationForm) => {
    const { match, receiptVerification } = this.props;
    receiptVerification(match.params.receiptId, verificationForm);
  };

  onMallChange = (mallId: any) => {
    const { tenantListData } = this.props;
    tenantListData(mallId);
  };

  onStatusChange = (statusId: any, rawReasonList: any) => {
    let reasonList;
    switch (statusId) {
      case "UNREAD":
        reasonList = rawReasonList.filter((status: { id: number }) => {
          return status.id == 1;
        });
        this.setState({
          reasonList,
        });
        break;
      case "VALID":
        reasonList = rawReasonList.filter((status: { id: number }) => {
          return status.id == 2;
        });
        this.setState({
          reasonList,
        });
        break;
      case "DOUBLE_ENTRY":
        reasonList = rawReasonList.filter((status: { id: number }) => {
          return status.id == 3;
        });
        this.setState({
          reasonList,
        });
        break;
      case "INVALID":
        reasonList = rawReasonList.filter((status: { id: number }) => {
          return status.id > 3;
        });
        this.setState({
          reasonList,
        });
        break;
      default:
    }
  };

  onCancel() {
    this.setState({
      reasonList: [],
    });
  }

  onDateTimeChange = (dateTime: any) => {
    this.setState({ dateTime });
    let transactionDate = dateTime
      ? moment(dateTime[0]).format("YYYY-MM-DD HH:mm:ss")
      : "";
    // store.dispatch(
    //   actions.change('collection.verificationForm.date', transactionDate)
    // );
  };

  render() {
    const { match, collection } = this.props;
    const { dateTime, reasonList } = this.state;
    const re = /^[0-9.]+$/;

    return (
      <>
        <Header
          breadcrumbs={[
            { link: "/dashboard/collections", text: "Collections" },
            {
              link: `/dashboard/collections/${match.params.receiptId}/verification`,
              text: "Receipt Detail & Verification",
            },
          ]}
        />
        <Container className="mt--7" fluid>
          <Row>
            <Col xs="12">
              <Card className="shadow">
                <CardHeader>
                  <h3 className="mb-0 text-left">
                    Receipt Detail & Verification
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={this.handleReceiptVerification}
                    model="collection.verificationForm"
                    validators={{
                      mall_id: {
                        isRequired: (val) => val,
                      },
                      tenant_id: {
                        isRequired: (val) => val,
                      },
                      total: {
                        isRequired: (val) => val,
                      },
                      date: {
                        isRequired: (val) => val,
                      },
                      payment_type_id: {
                        isRequired: (val) => val,
                      },
                      status: {
                        isRequired: (val) => val,
                      },
                      reason_id: {
                        isRequired: (val) => val,
                      },
                    }}
                  >
                    <Row>
                      <Col xs="12" md="6">
                        <FormGroup>
                          <Label>Location</Label>
                          <Input
                            disabled={true}
                            defaultValue={
                              collection.receiptDetail.location
                                ? `${collection.receiptDetail.location != null
                                  ? collection.receiptDetail.location.name
                                  : ""
                                }`
                                : ""
                            }
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Mall</Label>
                          <Control
                            model=".mall_id"
                            type="select"
                            mapProps={{
                              onChange: (props) => (value: any) => {
                                store.dispatch(
                                  actions.change(
                                    "collection.verificationForm.mall_id",
                                    value
                                  )
                                );
                                this.onMallChange(value.target.value);
                              },
                            }}
                            component={Input}
                            defaultValue=""
                          >
                            <option value="" disabled></option>
                            {collection.receiptData &&
                              collection.receiptData.malls.map(
                                (mall, index) => (
                                  <option key={index} value={mall.id}>
                                    {mall.name}
                                  </option>
                                )
                              )}
                          </Control>
                          <FormText color="danger">
                            <Errors
                              model=".mall_id"
                              show={{ touched: true, focus: false }}
                              messages={{
                                isRequired: "Mall is required. ",
                              }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Tenant</Label>
                          <Control
                            model=".tenant_id"
                            type="select"
                            component={Input}
                            defaultValue=""
                          >
                            <option value="" disabled></option>
                            {collection.receiptData &&
                              collection.receiptData.tenants.map(
                                (tenant, index) => (
                                  <option key={index} value={tenant.id}>
                                    {tenant.name}
                                  </option>
                                )
                              )}
                          </Control>
                          <FormText color="danger">
                            <Errors
                              model=".tenant_id"
                              show={{ touched: true, focus: false }}
                              messages={{
                                isRequired: "Tenant is required. ",
                              }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Member Name</Label>
                          <Input
                            disabled={true}
                            defaultValue={
                              collection.receiptDetail.member
                                ? `${collection.receiptDetail.member != null
                                  ? collection.receiptDetail.member.name
                                  : ""
                                } - ${collection.receiptDetail.member != null
                                  ? collection.receiptDetail.member.id
                                  : ""
                                }`
                                : ""
                            }
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>CCM</Label>
                          <Input
                            disabled={true}
                            value={`${collection.receiptDetail != null
                              ? collection.receiptDetail.ccm
                              : ""
                              }`}
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Transaction Date</Label>
                          <div>
                            <Flatpickr
                              className="form-control form-control-white"
                              data-disable-time
                              value={
                                collection.receiptDetail.date
                                  ? collection.receiptDetail.date
                                  : "Invalid Date"
                              }
                              onChange={this.onDateTimeChange}
                            />
                          </div>
                          <FormText color="danger">
                            <Errors
                              model=".date"
                              show={{ touched: true, focus: false }}
                              messages={{
                                isRequired: "Transaction Date is required. ",
                              }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Transaction Code</Label>
                          <Input
                            disabled={true}
                            value={`${collection.receiptDetail != null
                              ? collection.receiptDetail.transaction_code
                              : ""
                              }`}
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Total</Label>
                          <Control
                            model=".total"
                            type="number"
                            component={Input}
                          />
                          <FormText color="danger">
                            <Errors
                              model=".total"
                              show={{ touched: true, focus: false }}
                              messages={{
                                isRequired: "Total is required. ",
                              }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Payment Type</Label>
                          <Control
                            model=".payment_type_id"
                            type="select"
                            component={Input}
                          >
                            {collection.receiptData &&
                              collection.receiptData.payment_types.map(
                                (payment_type, index) => (
                                  <option key={index} value={payment_type.id}>
                                    {payment_type.name}
                                  </option>
                                )
                              )}
                          </Control>
                          <FormText color="danger">
                            <Errors
                              model=".payment_type_id"
                              show={{ touched: true, focus: false }}
                              messages={{
                                isRequired: "Payment Type is required. ",
                              }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Card Number</Label>
                          <Control
                            model=".card_number"
                            type="number"
                            component={Input}
                          />
                          <FormText color="danger">
                            <Errors
                              model=".card_number"
                              show={{ touched: true, focus: false }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Status</Label>
                          <Control
                            model="collection.verificationForm.status"
                            type="select"
                            mapProps={{
                              onChange: (props) => (value: any) => {
                                const { collection } = this.props;
                                store.dispatch(
                                  actions.change(
                                    "collection.verificationForm.status",
                                    value
                                  )
                                );
                                this.onStatusChange(
                                  value.target.value,
                                  collection.receiptData.reasons
                                );
                              },
                            }}
                            component={Input}
                          >
                            {collection.receiptData &&
                              collection.receiptData.statuses.map(
                                (status, index) => (
                                  <option key={index} value={status.id}>
                                    {status.name}
                                  </option>
                                )
                              )}
                          </Control>
                          <FormText color="danger">
                            <Errors
                              model=".status"
                              show={{ touched: true, focus: false }}
                              messages={{
                                isRequired: "Status is required. ",
                              }}
                            />
                          </FormText>
                        </FormGroup>
                        <FormGroup>
                          <Label>Reason</Label>
                          <Control
                            model=".reason_id"
                            type="select"
                            component={Input}
                          >
                            {reasonList &&
                              reasonList.map(
                                (
                                  reason: {
                                    id: string | number | string[] | undefined;
                                    name: React.ReactNode;
                                  },
                                  index: string | number | undefined
                                ) => (
                                  <option key={index} value={reason.id}>
                                    {reason.name}
                                  </option>
                                )
                              )}
                          </Control>
                        </FormGroup>
                        <FormGroup>
                          <Label>GEA Operator</Label>
                          <Input
                            disabled={true}
                            value={
                              collection.receiptDetail.operator != null
                                ? collection.receiptDetail
                                  .operator_verifying_at != null
                                  ? `${collection.receiptDetail.operator.name
                                  } - ${moment(
                                    collection.receiptDetail
                                      .operator_verifying_at
                                  ).format("DD/MM/YYYY HH:mm:ss")}`
                                  : `${collection.receiptDetail.operator.name} `
                                : ""
                            }
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Submitted On</Label>
                          <Input
                            disabled={true}
                            value={`${collection.receiptDetail != null
                              ? moment(
                                collection.receiptDetail
                                  .created_at
                              ).add(7, 'hours').format("DD/MM/YYYY HH:mm:ss")
                              : ""
                              }`}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6">
                        <div>
                          <img
                            id="receipt-image"
                            src={collection.receiptDetail.file_source}
                            alt="Receipt Image"
                          />
                        </div>
                      </Col>
                      <Col xs="12">
                        <FormGroup className="text-right">
                          <Button color="primary">Save</Button>
                          <Button
                            color="secondary"
                            onClick={this.onCancel}
                            tag={Link}
                            to={`/dashboard/collections`}
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStatesToProps = (state: ReducerState) => ({
  collection: state.collection,
});

const mapActionsToProps = {
  receiptData,
  receiptDataReset,
  receiptVerification,
  tenantListData,
};

export default connect(mapStatesToProps, mapActionsToProps)(Verification);
